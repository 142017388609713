import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

let routes = [
	{
		path: '/',
		name: 'home',
		component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
	},
	{
		path: '/privacy-policy',
		name: 'privacy',
		component: () => import('../pages/privacy.vue'),
	},
	{
		path: '/terms-of-services',
		name: 'terms',
		component: () => import('../pages/terms.vue'),
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('../pages/about.vue'),
	},
	{
		// 限制 channel 只能是 channel1, channel2, channel3
		path: '/:channel(channel1|channel2|channel3)',
		name: 'homechannel',
		component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
		props: true,
		beforeEnter: (to, from, next) => {
			console.log('访问的是 /' + to.params.channel)
			next()
		},
	},
	{
		// 匹配带有 channel 和 sub 的路径
		path: '/:channel(channel1|channel2|channel3)/:id-:cate-:keyword',
		name: 'childChannelPage',
		component: () => import(/* webpackChunkName: "childpage" */ '../pages/detail.vue'),
		props: true,
		beforeEnter: (to, from, next) => {
			// console.log('Channel:', to.params.channel)
			// console.log('Sub:', to.params.sub)
			next()
		},
	},
	{
		path: '/:id-:cate-:keyword', // 确保顺序与 URL 保持一致
		name: 'detail',
		component: () => import(/* webpackChunkName: "home" */ '../pages/detail.vue'),
	},
]

export default function createRouter() {
	return new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
	})
}
