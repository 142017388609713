const firebaseConfig = {
	apiKey: 'AIzaSyDLdVx60TsBn2mvNVfolqnXFnNU4eiw3M0',
	authDomain: 'webs-e251c.firebaseapp.com',
	projectId: 'webs-e251c',
	storageBucket: 'webs-e251c.firebasestorage.app',
	messagingSenderId: '1013823504636',
	appId: '1:1013823504636:web:5d4d4a5195866b7d44d779',
	measurementId: 'G-H1R6065FEL',
}

export default firebaseConfig
